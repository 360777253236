// Pinia Store
import { computed, inject, ref } from 'vue';
import type { AxiosStatic } from 'axios';
import type { Router } from 'vue-router';
import { defineStore } from 'pinia';
import { useStorage } from '@vueuse/core';

import PushNotification from '@/push-notification';

import { useAccountStore } from '@/stores/account';
import { useSocketStore } from '@/stores/socket';
import { useEntitiesStore } from '@/stores/entities';
import { useGlobalStore } from '@/stores/global';
import { useImpulsesStore } from '@/stores/impulses';
import { useQuestionnaireStore } from '@/stores/questionnaire';
import { useTreasureQuestStore } from '@/stores/treasureQuest';

console.log('Pinia Auth store is being created.'); // no access to Vue.prototype.$log here yet

const STATUS = {
  SIGNING_IN: 1,
  SIGNED_IN: 2,
  ERROR: 3,
  SIGNING_OUT: 4,
  SIGNED_OUT: 5,
} as const;

interface Tokens {
  accessToken?: string;
  refreshToken?: string;
}

let router: Router;

function injectRouter(routerParam: Router) {
  router = routerParam;
}

export const useAuthStore = defineStore('auth', () => {
  const $log: any = inject('$log');
  const $http: undefined | AxiosStatic = inject('$http');

  const accessToken = useStorage<string | null>('access-token', null);
  const refreshToken = useStorage<string | null>('refresh-token', null);
  const originalRefreshToken = useStorage<string | null>('original-token', null);
  const originalWindowHref = useStorage<string | null>('original-window-href', null);

  const initialized = ref(false);
  const status = ref<number>(STATUS.SIGNED_OUT);
  const refreshTokenPromise = ref<Promise<Tokens> | null>(null);

  const isAuthenticated = computed((): boolean => initialized.value && !!accessToken.value && !!refreshToken.value); // 2023-08-23 verify both tokens, as we can have the accessToken set during the GetReady program before registration, and we don't want this to return true in that case
  const isSigningOut = computed((): boolean => status.value === STATUS.SIGNING_OUT);
  const isImpersonating = computed(() => Boolean(originalRefreshToken.value));

  function updateRequestHeaders(accessToken?: string) {
    if (!$http) {
      $log?.warn('updateRequestHeaders: $http is not available');
      return;
    }
    if (accessToken) {
      // $log.debug('updateRequestHeaders: SET $http.defaults.headers.common.Authorization with new access token.');
      $http.defaults.headers.Authorization = `Bearer ${accessToken}`;
    } else {
      // $log.debug('updateRequestHeaders: DELETED $http.defaults.headers.common.Authorization (no access token)');
      delete $http.defaults.headers.Authorization;
    }
  }

  function setTokens(tokens: Tokens) {
    if (tokens.accessToken) {
      accessToken.value = tokens.accessToken;
      updateRequestHeaders(tokens.accessToken);
    }
    if (tokens.refreshToken) {
      refreshToken.value = tokens.refreshToken;
    }
  }

  function clearTokens() {
    accessToken.value = null;
    refreshToken.value = null;
    updateRequestHeaders();
  }

  function resetState() {
    initialized.value = false;
    status.value = STATUS.SIGNED_OUT;
    refreshTokenPromise.value = null;
    clearTokens();
    originalRefreshToken.value = null;
    originalWindowHref.value = null;
    sessionStorage.clear();
  }

  async function authenticated(tokens: Tokens) {
    $log?.debug('Authenticated.');
    status.value = STATUS.SIGNED_IN;
    setTokens(tokens);
    const globalStore = useGlobalStore();
    globalStore.userSignedIn();

    try {
      const accountStore = useAccountStore();
      await accountStore.fetchAccountAndSettings();
      const socketStore = useSocketStore();
      socketStore.initialize();
    } catch (error) {
      $log?.error('Error fetching profile:', error);
      throw error;
    }
  }

  async function signIn(payload: { user: { username: string; password: string } }) {
    status.value = STATUS.SIGNING_IN;
    try {
      const response = await $http?.post<{
        type: string;
        exp: number;
        token: string;
        refresh: string;
      }>('/auth/login', payload.user, { signal: undefined });
      if (!response) {
        throw new Error('No response from /auth/login');
      }
      const tokens: Tokens = {
        accessToken: response.data.token,
        refreshToken: response.data.refresh,
      };
      initialized.value = true;
      await authenticated(tokens);
    } catch (error) {
      $log?.warn('Error signing in:', error);
      clearTokens();
      status.value = STATUS.ERROR;
      throw error;
    }
  }

  async function signOut(options: { keepInSameRoute?: boolean; refresh?: boolean } = {}) {
    $log?.debug('Signing out.');
    status.value = STATUS.SIGNING_OUT;
    await PushNotification.signedOut();
    try {
      if ($http?.defaults.headers.Authorization) {
        await $http.delete('/auth/logout');
      }
    } catch (error) {
      $log?.error('Error during sign out:', error);
    } finally {
      clearTokens();
      resetState();
      // Reset other stores
      useAccountStore().resetState();
      useEntitiesStore().resetState();
      useGlobalStore().resetState();
      useImpulsesStore().resetState();
      useQuestionnaireStore().resetState();
      useSocketStore().resetState();
      useTreasureQuestStore().resetState();

      if (options.refresh) {
        window.location.reload();
      } else if (!options.keepInSameRoute && router) {
        await router.push({ name: 'SignedOut' });
      }
    }
  }

  function logErrorAndSignOut(message: string) {
    refreshTokenPromise.value = null;
    $log?.error(message);
    signOut({ refresh: true });
    throw new Error(message);
  }

  function setAccessTokenForJourneyAccess(token: string) {
    accessToken.value = token;
    updateRequestHeaders(token);
  }

  async function refreshTokenNow(fetchProfile: boolean): Promise<Tokens> {
    const logTag = 'refreshTokenNow';
    $log?.debug(logTag);
    //
    // Already refreshing?
    //
    if (refreshTokenPromise.value) {
      $log?.debug(`${logTag}: already refreshing token`);
      return refreshTokenPromise.value;
    }
    //
    // OK, let’s get a new token
    //
    const promise = (async () => {
      try {
        interface ExchangeResponse {
          data: {
            token: string;
            type: 'Bearer';
          };
        }
        const response: undefined | ExchangeResponse = await $http?.put(
          '/auth/exchange',
          { token: refreshToken.value },
          { signal: undefined },
        );

        if (response?.data?.token) {
          const tokens: Tokens = {
            accessToken: response.data.token,
            refreshToken: refreshToken.value!,
          };
          setTokens(tokens);

          // const DEBUG_INVALID = false;
          // if (DEBUG_INVALID) {
          //   setTimeout(() => {
          //     const invalidTokens = {
          //       accessToken: 'Bearer DEBUG_INVALID',
          //       refreshToken: tokens.refreshToken,
          //     };
          //     setLocalTokensAndUpdateRequestHeader(invalidTokens);
          //     commit('AUTH_SUCCESS', invalidTokens);
          //   }, 5000);
          // }

          if (fetchProfile) {
            const accountStore = useAccountStore();
            const { userProfile } = accountStore;
            const userProfileExists = userProfile && Object.keys(userProfile).length > 0;
            if (!userProfileExists) {
              // User profile does not exist yet, wait for it.
              await accountStore.fetchAccountAndSettings();
            } else {
              // User profile exists, do not request it now (wait 1 second) to allow
              // the original request to repeat if the token was invalid
              setTimeout(() => {
                // Fetch account, as the user might have a new role
                //
                // Delay this (allow repeating the request first)
                // because the original request could be updating the account
                // e.g. changing the language, and this fetch would overwrite the change
                //
                accountStore.fetchAccountAndSettings();
              }, 1000);
            }
          }
          return tokens;
        } else {
          //
          // No token in response
          //
          logErrorAndSignOut(`${logTag}: No token in response`);
          return {
            accessToken: '',
            refreshToken: '',
          };
        }
      } catch (error) {
        const BAD_GATEWAY = 502 as const;
        if (typeof error === 'object'
          && error
          && 'response' in error
          && typeof error.response === 'object'
          && error.response
          && 'status' in error.response
          && error.response.status === BAD_GATEWAY) {
          // deploy in progress? No need to sign out.
          // throw error;
        }
        const message = `${logTag}: failed getting a new token, will sign the user out: ${error}`;
        logErrorAndSignOut(message);
        return {
          accessToken: '',
          refreshToken: '',
        };
      } finally {
        //
        // Done. Clear the promise.
        //
        refreshTokenPromise.value = null;
      }
    })();

    refreshTokenPromise.value = promise;
    return promise;
  }

  async function refreshTokenAfter401Unauthorized(requestUrl: string) {
    //
    // Failed on refresh?
    //
    if (requestUrl && requestUrl.endsWith('/auth/exchange')) {
      // Requesting to refresh the token because the previous token refresh request failed? Stop.
      const message = 'Will not try to refresh the token for a failed refresh token request.';
      logErrorAndSignOut(message);
      throw new Error(message);
    }
    return refreshTokenNow(true);
  }

  async function initialize() {
    if (refreshToken.value) {
      $log?.debug('Initialize: refresh token exists, requesting new access token');
      try {
        // await new Promise((resolve) => setTimeout(resolve, 10000));
        const tokens = await refreshTokenNow(false);
        initialized.value = true;
        if (accessToken.value) {
          await authenticated(tokens);
        } else {
          await signOut({ keepInSameRoute: true });
        }
        // eslint-disable-next-line unused-imports/no-unused-vars
      } catch (_error) {
        await signOut({ keepInSameRoute: true });
      }
    } else {
      $log?.debug('Initialize: no refresh token, signing out');
      await signOut({ keepInSameRoute: true });
      initialized.value = true;
    }
  }

  async function impersonateUser(id: number) {
    const accountStore = useAccountStore();
    if (!(accountStore.userRoleIsAdministrator || accountStore.userRoleIsImpLAndDManager)) {
      $log.warn('impersonateUser: current user is not Admin or L&D Manager');
      return;
    }
    try {
      interface ModuleResponse {
        data: {
          token: string;
          type: string;
        };
      }

      const response: undefined | ModuleResponse = await $http?.get(`/auth/impersonate/${id}`);
      if (response?.data?.token) {
        $log?.info(`impersonateUser: will impersonate user ${id}`);
        originalRefreshToken.value = refreshToken.value;
        originalWindowHref.value = window.location.href;
        refreshToken.value = response.data.token;
        setTimeout(() => window.location.reload(), 100);
      }
    } catch (error) {
      $log?.debug('impersonateUser error:', error);
    }
  }

  function exitImpersonate() {
    if (originalRefreshToken.value) {
      refreshToken.value = originalRefreshToken.value;
      originalRefreshToken.value = null;

      setTimeout(() => {
        if (originalWindowHref.value) {
          window.location.href = originalWindowHref.value;
          originalWindowHref.value = null;
        } else {
          window.location.reload();
        }
      }, 100);
    }
  }

  return {
    //
    // State
    //
    accessToken,
    //
    // Getters
    //
    isAuthenticated,
    isSigningOut, // used by the router
    isImpersonating, // header and logger
    //
    // Actions
    //
    injectRouter, // main
    // resetState,
    initialize, // main
    // authenticated,
    setAccessTokenForJourneyAccess, // InvitationView
    refreshTokenAfter401Unauthorized, // main
    // refreshTokenNow,
    signIn,
    signOut,
    impersonateUser,
    exitImpersonate,
  };
});
