import * as Sentry from '@sentry/vue';
import axios from 'axios';
import type { App, ComponentCustomProperties } from 'vue';
import { useAuthStore } from '@/stores/auth';
import { useAccountStore } from '@/stores/account';
import { useGlobalStore } from '@/stores/global';

const APP_ID = '2290075c-a21c-11e8-84e1-ec21e5082f22';

type LogLevel = 'debug' | 'info' | 'warn' | 'error' | 'fatal';

export interface Options {
  // optional : defaults to true if not specified
  isEnabled?: boolean;
  // required ['debug', 'info', 'warn', 'error', 'fatal']
  logLevel: LogLevel;
  // optional : defaults to false if not specified
  stringifyArguments?: boolean;
  // optional : defaults to false if not specified
  showLogLevel?: boolean;
  // optional : defaults to false if not specified
  showMethodName?: boolean;
  // optional : defaults to '|' if not specified
  separator?: string;
  // optional : defaults to false if not specified
  showConsoleColors?: boolean;
}

type ReplacerFn = (key: string, value: any) => any;

export default (() => {
  const defaultOptions: Required<Options> = {
    isEnabled: true,
    logLevel: 'debug',
    separator: '|',
    stringifyArguments: false,
    showLogLevel: false,
    showMethodName: false,
    showConsoleColors: false,
  };

  const logLevelsArray: LogLevel[] = ['debug', 'info', 'warn', 'error', 'fatal'];

  function serializer(replacer?: ReplacerFn, cycleReplacerArg?: ReplacerFn) {
    const stack: any[] = [];
    const keys: string[] = [];

    const cycleReplacer = cycleReplacerArg || function (_key: string, value: any) {
      if (stack[0] === value) {
        return '[Circular ~]';
      }
      return `[Circular ~.${keys.slice(0, stack.indexOf(value)).join('.')}]`;
    };

    return function (this: any, key: string, value: any) {
      if (stack.length > 0) {
        const thisPos = stack.indexOf(this);
        if (thisPos !== -1) {
          stack.splice(thisPos + 1);
          keys.splice(thisPos, Infinity, key);
        } else {
          stack.push(this);
          keys.push(key);
        }
        if (stack.includes(value)) {
          value = cycleReplacer.call(this, key, value);
        }
      } else {
        stack.push(value);
      }

      return replacer ? replacer.call(this, key, value) : value;
    };
  }

  function stringifySafe(obj: any, replacer?: ReplacerFn, spaces?: number, cycleReplacer?: ReplacerFn): string {
    // https://github.com/moll/json-stringify-safe/blob/master/stringify.js
    return JSON.stringify(obj, serializer(replacer, cycleReplacer), spaces);
  }

  function getMethodName() {
    // eslint-disable-next-line unicorn/error-message
    const error = new Error();
    if (!error.stack) {
      return '(no error.stack)';
    }
    const stackLines = error.stack.split('\n');
    const callerLine = stackLines[3] || stackLines[2];
    if (!callerLine) {
      return '(unknown method)';
    }
    const methodMatch = callerLine.match(/at (\S+)/);
    return methodMatch ? methodMatch[1] : '(anonymous function)';
  }

  async function printLogMessage(
    app: App,
    logLevel: LogLevel,
    logLevelPrefix: string,
    methodNamePrefix: string,
    formattedArguments: unknown[],
    options: Required<Options>,
  ) {
    if (options.showConsoleColors && ['warn', 'error', 'fatal'].includes(logLevel)) {
      console[logLevel === 'fatal' ? 'error' : logLevel](logLevelPrefix, methodNamePrefix, ...formattedArguments);
    } else {
      console.log(logLevelPrefix, methodNamePrefix, ...formattedArguments);
    }

    if (!formattedArguments || formattedArguments.length === 0) {
      return;
    }

    if (formattedArguments[0] === 'Connection lost?') {
      // Do not report
      return;
    }

    const isHttpCancel = formattedArguments.some((arg) => axios.isCancel(arg));
    if (isHttpCancel) {
      if (process.env.NODE_ENV !== 'production') {
        console.log('(development environment). Log argument is HTTP Cancel. Will not report.');
      }
      return;
    }

    //
    // Report (send to backend)
    //
    if (logLevel && ['fatal', 'error', 'warn', 'info'].includes(logLevel)) {
      const authStore = useAuthStore();
      const globalStore = useGlobalStore();
      const accountStore = useAccountStore();

      let message = '';

      const { currentVersion } = globalStore;
      message += `v${currentVersion || '?.?.?'} |`;

      const userId = accountStore.userProfile?.id;
      if (userId) {
        message += authStore.isImpersonating
          ? ` [IMPERSONATED] User ID ${userId} |`
          : ` User ID ${userId} |`;
      } else {
        message += ' No user ID |';
      }

      const clientIp = await getIP();

      if (clientIp) {
        message += ` ${clientIp} |`;
      }

      const { userRole } = accountStore;
      if (userRole) {
        message += ` Role ID ${userRole.id} (${userRole.code} / ${userRole.name}) |`;
      } else {
        message += ' No role |';
      }

      const router = app.config.globalProperties.$router;
      const routeInfo = router
        ? `route: ${router.currentRoute.value.name?.toString()} q: ${stringifySafe(router.currentRoute.value.query)}`
        : `no router; window location href: ${window.location.href}`;

      message += ` ${routeInfo} |`;

      message += ` ${logLevelPrefix} ${methodNamePrefix} ${formattedArguments
        .map((arg) => (typeof arg === 'string' ? arg : stringifySafe(arg)))
        .join(' ')}`;

      if (authStore.isAuthenticated) {
        const postData = {
          id: APP_ID,
          level: logLevel === 'fatal' ? 'error' : logLevel, // possible: error, warn, info
          message,
        };

        if (process.env.NODE_ENV !== 'production') {
          // console.log('Development build. Not POSTing log. Would send:', postData);
          return;
        }

        // Send to Sentry
        if (['fatal', 'error', 'warn'].includes(logLevel)) {
          Sentry.captureMessage(message);
        }

        // Send to backend
        try {
          const http = app.config.globalProperties.$http;
          if (http) {
            await http.post('/session/log', postData, {
              doNotLogErrorAgainIfThisRequestFails: true,
              signal: undefined,
            });
          }
        } catch {
          console.warn('Could not log the message. Is the server running?');
        }
      }
    }
  }

  function initLoggerInstance(app: App, options: Required<Options>, logLevels: LogLevel[]) {
    const logger: {
      [level in LogLevel]: (...data: any[]) => void;
    } = {} as any;

    logLevels.forEach((logLevel) => {
      if (logLevels.indexOf(logLevel) >= logLevels.indexOf(options.logLevel) && options.isEnabled) {
        logger[logLevel] = (...args: unknown[]) => {
          const methodName = getMethodName();
          const methodNamePrefix = options.showMethodName ? `${methodName} ${options.separator}` : '';
          const logLevelPrefix = options.showLogLevel ? `${logLevel} ${options.separator}` : '';
          const formattedArguments = options.stringifyArguments ? args.map((a) => stringifySafe(a)) : args;

          printLogMessage(
            app,
            logLevel,
            logLevelPrefix,
            methodNamePrefix,
            formattedArguments,
            options,
          );
        };
      } else {
        logger[logLevel] = () => {
        };
      }
    });
    return logger;
  }

  function isValidOptions(options: Options): options is Required<Options> {
    const validatedOptions: any = { ...defaultOptions, ...options };

    if (!logLevelsArray.includes(validatedOptions.logLevel)) {
      return false;
    }

    for (const key of Object.keys(defaultOptions)) {
      const expectedType = typeof defaultOptions[key as keyof Options];
      // eslint-disable-next-line valid-typeof
      if (typeof validatedOptions[key] !== expectedType) {
        return false;
      }
    }

    if (typeof validatedOptions.separator === 'string' && validatedOptions.separator.length > 3) {
      return false;
    }

    return true;
  }

  let cachedClientIp: string | null = null;

  async function getIP(): Promise<string | null> {
    if (cachedClientIp !== null) {
      return cachedClientIp;
    }
    try {
      const response = await fetch('https://api.ipify.org/?format=json');
      const json = await response.json();
      if (json && 'ip' in json) {
        cachedClientIp = json.ip;
        return cachedClientIp;
      }
    } catch (error) {
      console.error('getIP: there was an error', error);
    }
    return null;
  }

  function install(appParam: App, optionsArg: Options) {
    const options = { ...defaultOptions, ...optionsArg } as Required<Options>;

    if (!isValidOptions(options)) {
      throw new Error('Provided options for the logger are not valid.');
    }

    const logger = initLoggerInstance(appParam, options, logLevelsArray);
    appParam.config.globalProperties.$log = logger as ComponentCustomProperties['$log'];
  }

  return {
    install,
  };
})();
